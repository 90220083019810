import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Tooltip, Typography } from '@material-ui/core'
import { getLiveSockets, disconnectSocket } from 'api/live'
import { dateTimeToString } from 'utils/date'
import { textFilter } from 'utils/table'
import { AccessTypeLabels, AccessTypes } from 'shared/constants'
import InformationTable from 'components/InformationTable/InformationTable'
import SearchInput from 'elements/SearchInput/SearchInput'
import LoadingButton from 'elements/LoadingButton/LoadingButton'
import Enlace from 'elements/Enlace/Enlace'
import WrapperLink from 'elements/WrapperLink/WrapperLink'
import LogTimeAnalyzer from './LogTimeAnalyzer'
import styles from './ClientesConectados.module.css'

const TEACHER = 'TEACHER'
const STUDENT = 'STUDENT'

const getTecherInfo = socket => {
  const studentLength = socket.classroom?.students.length

  return studentLength ? ` (${studentLength} alumnos)` : ` (Sin alumnos)`
}
const getTooltipInfo = (currentSocket = {}, sockets = []) => {
  const { role, classroom } = currentSocket
  if (role === STUDENT)
    return sockets.find(({ _id }) => _id === classroom.teacher)?.name || ''
  if (role === TEACHER)
    return sockets
      .filter(({ _id }) => classroom?.students?.includes(_id))
      .map(student => student?.name)
  return '---'
}

const ProfileLink = ({ role, _id, name, info }) => {
  if (role === TEACHER)
    return (
      <Tooltip
        placement='bottom-start'
        title={
          <>
            Alumnos:
            {info.map((studentName, i) => (
              <div key={i}>
                {studentName}
                <br />
              </div>
            ))}
          </>
        }
      >
        <div>
          <Enlace href='https://core.mundoestudiante.cloud/personal/profesorado'>
            {name}
          </Enlace>
        </div>
      </Tooltip>
    )
  if (role === STUDENT)
    return (
      <Tooltip
        placement='bottom-start'
        title={
          <>
            Profesor:
            <br />
            {info}
          </>
        }
      >
        <div>
          <WrapperLink to={`/clients/${_id}`}>{name}</WrapperLink>
        </div>
      </Tooltip>
    )
  return '--'
}

const Tag = ({ socket }) => {
  return (
    <div
      className={[styles.tag, styles[socket.accessType || 'teacher']].join(' ')}
    >
      {(AccessTypeLabels[socket.accessType] || 'Profe').concat(
        socket.customInfo || ''
      )}
    </div>
  )
}

const ClientesConectados = () => {
  const [search, setSearch] = useState('')
  const [sockets, setSockets] = useState([])
  const [showModal, setShowModal] = useState(false)

  const fetchData = useCallback(() => {
    getLiveSockets()
      .then(data => {
        setSockets(
          data.map(connection => ({
            ...connection,
            connectionDate: new Date(connection.connectionTS)
          }))
        )
      })
      .catch(console.error)
  }, [])
  const handleDisconnect = useCallback(
    socketId => {
      disconnectSocket({ socketId })
        .then(fetchData)
        .catch(e => {
          console.error('Error disconnecting socket: ', e)
          fetchData()
        })
    },
    [fetchData]
  )

  const filterSearch = live => {
    return textFilter({
      object: live,
      fields: ['name', 'role', 'accessType'],
      search: search
    })
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const orderedData = useMemo(() => {
    const studentWithoutTeacher = sockets.filter(socket => {
      if (socket.role === STUDENT && !socket.classroom?.teacher) {
        socket.customStyle = { background: 'var(--secondary-color-30)' }
        socket.customInfo = ' (En sala de espera)'
        return socket
      }
    })
    const studentsInBackground = sockets.filter(socket => {
      if (socket.role === STUDENT) {
        const teacher = sockets.find(
          ({ _id }) => socket.classroom?.teacher === _id
        )
        if (!teacher) return
        if (!teacher.classroom?.students?.includes(socket._id)) {
          socket.customStyle = { background: 'var(--warning-color-30)' }
          socket.customInfo = ' (¡Necesita atención!)'
          return socket
        }
      }
    })
    const teacherWithStudentsInOrder = sockets.reduce((acc = [], socket) => {
      if (socket.role === TEACHER) {
        socket.customInfo = getTecherInfo(socket)
        socket.classroom.students.forEach(studentId => {
          if (studentWithoutTeacher.map(({ _id }) => _id).includes(studentId)) {
            socket.customStyle = { background: 'var(--warning-color-10)' }
            return
          }
          if (!sockets.find(s => s._id === studentId))
            socket.customStyle = { background: 'var(--warning-color-10)' }
        })
        acc.push(socket)
        const studentIds = socket.classroom?.students
        if (!!studentIds.length) {
          const filteredStudents = sockets.filter(
            s =>
              studentIds.includes(s._id) && s.classroom?.teacher === socket._id
          )
          const trainees = filteredStudents.filter(
            student => student?.accessType === AccessTypes.TRAINEE
          )
          const leads = filteredStudents.filter(
            student => student?.accessType === AccessTypes.TRIAL
          )
          const others = filteredStudents.filter(
            student => student?.accessType === AccessTypes.STUDENT
          )
          acc.push(...trainees.concat(leads).concat(others))
        }
      }
      return acc
    }, [])

    return studentsInBackground
      .concat(studentWithoutTeacher)
      .concat(teacherWithStudentsInOrder)
  }, [sockets])

  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <LoadingButton
          label='Intervalos de tiempo'
          startIcon={<i className='material-icons'>av_timer</i>}
          variant='outlined'
          onClick={() => setShowModal(true)}
          disabled
        />
        <Typography variant='button' color='textSecondary'>
          Hay {orderedData.length} usuarios conectados
        </Typography>
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          { title: 'Info', key: 'info', sortDisabled: true, align: 'center' },
          { title: 'Dispositivo', key: 'device' },
          { title: 'Hora conexión', key: 'connectionTS', sortDisabled: true },
          { title: 'Opciones', key: 'options', sortDisabled: true }
        ]}
        data={orderedData.filter(filterSearch).map(socket => ({
          _id: socket._id,
          name: (
            <ProfileLink
              _id={socket._id}
              name={socket.name}
              role={socket.role}
              info={getTooltipInfo(socket, sockets)}
            />
          ),
          info: <Tag socket={socket} />,
          device: socket.device,
          connectionTS: dateTimeToString(socket.connectionTS),
          options: (
            <Button
              color='primary'
              onClick={() => handleDisconnect(socket._id)}
            >
              Desconectar
            </Button>
          ),
          style: socket.customStyle
        }))}
      />
      {showModal && <LogTimeAnalyzer onClose={() => setShowModal(false)} />}
    </div>
  )
}

export default ClientesConectados
