import React from 'react'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import styles from './MonthSelector.module.css'

const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

const MonthSelector = ({ value, onChange, ...rest }) => (
  <FormControl className={styles.monthSelector} margin='normal' {...rest}>
    <InputLabel id='selector-meses'>Mes</InputLabel>
    <Select
      labelId='selector-meses'
      value={value}
      onChange={onChange}
      input={<Input />}
    >
      {meses.map((mes, i) => (
        <MenuItem key={mes} value={i + 1}>
          {mes}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default MonthSelector
