export const dashboardOptions = {
  top: {
    Dashboard: {
      to: '/',
      icon: 'home',
      title: 'Dashboard'
    },
    Students: {
      to: '/clients',
      icon: 'face',
      title: 'Clientes'
    },
    Ticketing: {
      to: '/ticketing',
      icon: 'contact_mail',
      title: 'Ticketing'
    },
    SpecialRequests: {
      to: '/special-requests',
      icon: 'warning',
      title: 'Peticiones especiales',
      showCount: true
    }
  },
  bottom: {
    Connections: {
      to: '/online/connections',
      icon: 'settings_input_antenna',
      title: 'Conexiones'
    }
  }
}
