//@ts-check
import { isEmpty, pipe, trim } from 'ramda'

export const saveAuthentication = data => {
  const { token, ...rest } = data
  localStorage.setItem('online_access_token', token)
  localStorage.setItem('online_access_details', JSON.stringify(rest))
}

export const clearAuthentication = () => {
  localStorage.removeItem('online_access_token')
  localStorage.removeItem('online_access_details')
}

export const isAuthenticated = () => {
  return localStorage.getItem('online_access_token')
}

export const getAuthenticationToken = () => {
  return localStorage.getItem('online_access_token')
}

export const getAuthenticationDetails = () => {
  return JSON.parse(localStorage.getItem('online_access_details'))
}

export const formatNumberForCurrency = number => {
  return (number / 100).toFixed(2)
}

/**
 *
 * @param {{target:{name: string | array, value: any}}} e
 * @returns {{path: string[], value: any}}
 */
export function makeCustomChangeEvent(e) {
  if (!e) {
    console.warn(
      '[commonFormChange]: an event ({target:{name: string, value: any}}) is needed'
    )
    return
  }
  if (Array.isArray(e.target.name))
    return { path: e.target.name, value: e.target.value }
  return { path: e.target.name.split('.'), value: e.target.value }
}

/**
 *
 * @param {{target:{name: string | array, checked: boolean}}} e
 * @returns {{path: string[], checked: any}}
 */
export function makeCustomCheckChangeEvent(e) {
  if (!e) {
    console.warn(
      '[commonFormChange]: an event ({target:{name: string, value: any}}) is needed'
    )
    return
  }
  if (Array.isArray(e.target.name))
    return { path: e.target.name, checked: e.target.checked }
  return { path: e.target.name.split('.'), checked: e.target.checked }
}

/**
 *
 * @param {object} query
 * @returns {string}
 */
export const makeQueryString = (query = {}) =>
  Object.keys(query)
    .reduce((acc, key) => {
      if (!!query[key]?.$in) acc.push(`${key}=${JSON.stringify(query[key])}`)
      else if (query.$or) acc.push(`${key}=${JSON.stringify(query[key])}`)
      else acc.push(`${key}=${query[key]}`)
      return acc
    }, [])
    .join('&')

/**
 *
 * @param {object} params
 * @param {object} params.query
 * @param {string[]} [params.project]
 * @param {{page:number, pageSize:number, sort?:object}} [params.pagination]
 * @param {string} params.url
 * @returns {string}
 */
export const makeCustomQueryAndProject = ({
  query = {},
  project = [],
  pagination,
  url = ''
}) => {
  let finalUrl = url
  const hasQuery = query && !isEmpty(query)
  const hasProject = project && !isEmpty(project)
  if (hasQuery) finalUrl += '?' + makeQueryString(query)
  if (hasProject)
    finalUrl += hasQuery
      ? `&project=${project.join(',')}`
      : `?project=${project.join(',')}`
  if (pagination && !isEmpty(pagination))
    finalUrl +=
      hasQuery || hasProject
        ? `&pagination=${JSON.stringify(pagination, null, 2)}`
        : `?pagination=${JSON.stringify(pagination, null, 2)}`
  return finalUrl
}

/**
 *
 * @param {string} stringHour "00:00 - 01:00"
 * @returns {{minHour: number, minMinutes:number, maxHour:number, maxMinutes:number}}
 */
export const extractRangeHoursAndMinutes = stringHour => {
  const [[minHour = 0, minMinutes = 0], [maxHour = 0, maxMinutes = 0]] =
    stringHour.split('-').map(pipe(trim, sh => sh.split(':')))
  return {
    minHour: Number(minHour),
    minMinutes: Number(minMinutes),
    maxHour: Number(maxHour),
    maxMinutes: Number(maxMinutes)
  }
}
