const textByEnvironment = {
  development: 'Entorno de desarrollo 🛠',
  preproduction: 'Entorno de pruebas 👨‍🔬'
}
const config = {
  isDev: process.env.REACT_APP_ENV === 'development',
  environmentTag:
    textByEnvironment[process.env.REACT_APP_ENV] ||
    textByEnvironment[process.env.NODE_ENV],
  host: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  stripe_key_sei:
    process.env.REACT_APP_STRIPE_TOKEN ||
    'pk_test_51JmEBlLn33caCtr1yo3cuTTOQcZ3k5UqSV1IFrsV4salTPsWvnOpcbTkX4OEBg7wVYyWNEEjPoz0LYDMrKlBaVbK002YGJjQtj',
  agora: {
    authUrl:
      process.env.REACT_APP_AGORA_AUTH_URL ||
      'https://api.mundoestudiante.cloud'
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
  }
}

export default config
