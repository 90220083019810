import config from 'config'

export const agoraLogin = ({ credential }) => {
  const params = new URLSearchParams({ token: credential })
  return fetch(config.agora.authUrl + '/login/agora?' + params, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    const JSONResponse = await response.json()
    if (response.ok) return JSONResponse
    throw new Error(JSONResponse.motivo || 'Error in agora login')
  })
}
