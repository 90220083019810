import React from 'react'

const CuadernilloCell = ({ cuadernillo }) => {
  if (!cuadernillo) return ''

  return (
    <a href={cuadernillo.url} target='_blank'>
      {cuadernillo.title}
    </a>
  )
}

export default CuadernilloCell
