import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Checkbox, Chip, FormControlLabel, Typography } from '@material-ui/core'
import { findClients } from 'api/clients'
import InformationTable from 'components/InformationTable/InformationTable'
import SearchInput from 'elements/SearchInput/SearchInput'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import Enlace from 'elements/Enlace/Enlace'
import WrapperLink from 'elements/WrapperLink/WrapperLink'
import {
  AccessTypeLabels,
  AccessTypes,
  RelativePathByAccessType
} from 'shared/constants'
import StatusIcon from 'shared/StatusIcon'
import { textFilter } from 'utils/table'
import { downloadCSVFile, getCSVContent } from 'utils/csv'
import styles from './ListadoClientes.module.css'

const ColorByAccessType = {
  [AccessTypes.STUDENT]: 'primary',
  [AccessTypes.TRIAL]: 'secondary'
}

const ListadoClientes = () => {
  const [search, setSearch] = useState('')
  const [showMore, setShowMore] = useState(false)
  const { setErrorMessage } = useMessageSnackbarActions()

  const { isFetching, data: clients = [] } = useQuery(
    ['client-list'],
    findClients,
    {
      refetchOnWindowFocus: false,
      onError(e) {
        setErrorMessage()
      }
    }
  )
  const filterSearch = subject => {
    return textFilter({
      object: subject,
      fields: ['cliente.name', 'email', 'cliente.phone'],
      search: search
    })
  }

  const handleExport = () => {
    const content = getCSVContent({
      columns: ['Nombre', 'Teléfono', 'E-mail', 'Tipo'],
      data: clients,
      transform: c => [
        c.cliente.name,
        c.cliente.phone,
        c.email,
        AccessTypeLabels[c.cliente.accessType]
      ]
    })
    downloadCSVFile(content, 'clientes')
  }

  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput
          value={search}
          onChange={e => setSearch(e.target.value)}
          autoFocus
        />
        <Typography variant='button' color='textSecondary'>
          {clients.length} clientes
        </Typography>
        <div className={styles.actions}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showMore}
                onChange={() => setShowMore(state => !state)}
              />
            }
            label='Ver más datos'
          />
          <div className={styles.download} onClick={handleExport}>
            <i className='material-icons'>download</i>
          </div>
        </div>
      </div>

      <InformationTable
        className={styles.table}
        isFetching={isFetching}
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email',
            align: 'left'
          },
          {
            title: 'Teléfono',
            key: 'phone',
            align: 'left',
            cell: styles.phoneCell
          },
          {
            title: 'Tipo',
            key: 'accessType'
          },
          { title: 'Email Verificado', key: 'email_verificado' },
          { title: 'Activo', key: 'activo' },
          { title: 'Permisos', key: 'permissions' },
          {
            title: 'Ágora',
            key: 'agora',
            align: 'center',
            cell: styles.agoraCell
          }
        ].filter(d => {
          if (!showMore)
            return !['email_verificado', 'activo', 'permissions'].includes(
              d.key
            )
          return d
        })}
        data={clients
          .filter(filterSearch)
          .map(
            ({
              email,
              email_verificado,
              acceso_classfy,
              acceso_app,
              acceso_barbeibot,
              activo,
              cliente = {}
            }) => ({
              email: email,
              agora: (
                <Enlace
                  href={`https://agora.mundoestudiante.cloud/${
                    RelativePathByAccessType[cliente.accessType]
                  }/${cliente.id}/ficha`}
                  target='_blank'
                >
                  <i className='material-icons'>remove_red_eye</i>
                </Enlace>
              ),
              name: (
                <WrapperLink to={`/clients/${cliente.id}`} color='secondary'>
                  {cliente.name || '--'}
                </WrapperLink>
              ),
              accessType: (
                <Chip
                  label={AccessTypeLabels[cliente.accessType]}
                  color={ColorByAccessType[cliente.accessType]}
                />
              ),
              phone: cliente.phone,
              email_verificado: <StatusIcon active={email_verificado} />,
              activo: <StatusIcon active={activo} />,
              permissions: (
                <div className={styles.permissions}>
                  {acceso_classfy && (
                    <Chip label='Classfy' size='small' color='secondary' />
                  )}
                  {acceso_app && (
                    <Chip label='App' size='small' color='secondary' />
                  )}
                  {acceso_barbeibot && (
                    <Chip label='Babeibot' size='small' color='secondary' />
                  )}
                </div>
              )
            })
          )}
        size='small'
      />
    </div>
  )
}

export default ListadoClientes
