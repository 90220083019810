import { AppBar, Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import ClasesCliente from './tabs/ClasesCliente'
import CuadernillosCliente from './tabs/CuadernillosCliente'
import DatosCliente from './tabs/DatosCliente'
import FotosCliente from './tabs/FotosCliente'
import styles from './FichaCliente.module.css'

const TabPanel = ({ active, children }) => {
  if (!active) return null
  return children
}

const ITEMS = {
  Ficha: DatosCliente,
  Clases: ClasesCliente,
  Cuadernillos: CuadernillosCliente,
  Fotos: FotosCliente
}
const FichaCliente = () => {
  const [tab, setTab] = useState(0)

  const handleChange = (_e, newValue) => setTab(newValue)

  return (
    <div className={styles.main}>
      <AppBar position='static'>
        <Tabs value={tab} onChange={handleChange} variant='fullWidth'>
          {Object.keys(ITEMS)
            .filter(
              label =>
                !process.env.REACT_APP_HIDE_STUDENT_TAB_ITEMS?.split(
                  ','
                ).includes(label)
            )
            .map((label, i) => {
              return <Tab key={i} label={label} />
            })}
        </Tabs>
      </AppBar>
      {Object.entries(ITEMS)
        .filter(
          ([label]) =>
            !process.env.REACT_APP_HIDE_STUDENT_TAB_ITEMS?.split(',').includes(
              label
            )
        )
        .map(([label, Component], i) => {
          return (
            <TabPanel key={i} active={tab === i}>
              <Component />
            </TabPanel>
          )
        })}
    </div>
  )
}

export default FichaCliente
