import React from 'react'
import { times } from 'ramda'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import styles from './YearSelector.module.css'

const start = 2020
const length = new Date().getFullYear() - start + 1
const years = times(i => new Date().getFullYear() - i, length)
const YearSelector = ({ value, onChange, ...rest }) => (
  <FormControl className={styles.yearSelector} margin='normal' {...rest}>
    <InputLabel id='selector-years'>Año</InputLabel>
    <Select
      labelId='selector-years'
      value={value}
      onChange={onChange}
      input={<Input />}
    >
      {years.map(year => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default YearSelector
