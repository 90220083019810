import React from 'react'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core'
import AccessTypeSelector from 'elements/AccessTypeSelector/AccessTypeSelector'
import { AccessTypeLabels, RelativePathByAccessType } from 'shared/constants'
import styles from './ClientForm.module.css'

function ClientForm({ client }) {
  const { activo, email, cliente } = client || {}
  return (
    <Card variant='outlined' className={styles.card}>
      <CardHeader
        title={`Ficha ${AccessTypeLabels[cliente?.accessType]}`}
        action={
          <Typography variant='button' component={Button}>
            <a
              href={`https://agora.mundoestudiante.cloud/${
                RelativePathByAccessType[cliente?.accessType]
              }/${cliente.id}/ficha`}
              target='_blank'
            >
              Ver {AccessTypeLabels[cliente?.accessType]} en Ágora
            </a>
          </Typography>
        }
      />
      <CardContent className={styles.content}>
        <TextField label='Nombre' value={cliente.name || ''} fullWidth />
        <TextField
          margin='normal'
          label='Correo electrónico'
          value={email || ''}
          fullWidth
        />

        <AccessTypeSelector
          value={cliente.accessType || ''}
          disabled
          fullWidth
        />

        <FormControlLabel
          control={<Checkbox name='active' checked={!!activo} />}
          label='Activo'
        />
      </CardContent>
    </Card>
  )
}

export default ClientForm
