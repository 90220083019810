import React from 'react'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import useFastInput from 'hooks/useFastInput'

export default ({
  value,
  onChange,
  autoFocus = false,
  useDebounced = false,
  onClick = () => {}
}) => {
  const { newInputValue, handleFastChange } = useFastInput({
    value,
    onChange,
    useDebounced
  })

  return (
    <FormControl margin='normal'>
      <InputLabel htmlFor='search'>Buscar</InputLabel>
      <Input
        id='search'
        value={newInputValue}
        onChange={handleFastChange}
        endAdornment={
          <InputAdornment position='start' onClick={onClick}>
            <i className='material-icons'>search</i>
          </InputAdornment>
        }
        autoFocus={autoFocus}
      />
    </FormControl>
  )
}
