//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { assoc, compose, path, prop, sortBy, toLower } from 'ramda'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { calculateTimeIntervals, listLogFiles } from 'api/scripts'
import { findClients } from 'api/clients'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import Spinner from 'elements/Spinner/Spinner'
import AutocompleteSelect from 'shared/AutocompleteSelect'
import ConfirmModal from 'shared/ConfirmModal'
import styles from './LogTimeAnalyzer.module.css'

const sortByNameCaseInsensitive = sortBy(
  compose(toLower, path(['cliente', 'name']))
)
function makeAutoCompleteClient(client = {}) {
  const { email, cliente } = client || {}
  client.clientId = cliente.id
  client.name = `${cliente.name} (${email})`
  return client
}

const parseFilenameToDate = filename => {
  const [day, month, year] = filename.split('.')[0].split('-')
  return new Date(`${year}-${month}-${day}`)
}

function LogTimeAnalyzer({ onClose }) {
  const [
    { clients, clientId, files, selectedFiles, summary, isFetching, isSending },
    setState
  ] = useState({
    clients: [],
    clientId: '',
    files: [],
    selectedFiles: [],
    summary: '',
    isFetching: false,
    isSending: false
  })

  const { setErrorMessage, setSuccessMessage } = useMessageSnackbarActions()

  const handleClientIdChange = e => setState(assoc('clientId', e.target.value))
  const handleFilesChange = (e, file) => {
    if (e.target.checked)
      return setState(prevState =>
        assoc('selectedFiles', [...prevState.selectedFiles, file], prevState)
      )
    setState(prevState =>
      assoc(
        'selectedFiles',
        prevState.selectedFiles.filter(f => f !== file),
        prevState
      )
    )
  }

  const handleSubmit = () => {
    setState(assoc('isSending', true))

    calculateTimeIntervals({ clientId, files: selectedFiles })
      .then(summary => setState(assoc('summary', summary)))
      .catch(e => {
        console.error(e)
        setErrorMessage(e.message)
      })
      .finally(() => setState(assoc('isSending', false)))
  }
  const handleDownload = () => {
    const blob = new Blob([summary], { type: 'text/plain' })

    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download =
      clients.find(({ _id }) => _id === clientId)?.name +
      new Date().toLocaleString() +
      '.log'

    a.click()

    window.URL.revokeObjectURL(a.href)
  }

  const fetchData = useCallback(async () => {
    try {
      setState(assoc('isFetching', true))

      const files = await listLogFiles()
      const clients = await findClients().then(list =>
        sortByNameCaseInsensitive(list).map(makeAutoCompleteClient)
      )

      setState(prevState => ({ ...prevState, files, clients }))
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setState(assoc('isFetching', false))
    }
  }, [setErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <ConfirmModal
      title='Obtener intervalos de tiempo'
      subtitle='Selecciona un cliente y los archivos que necesites consultar'
      isLoading={isSending}
      onOk={summary ? handleDownload : handleSubmit}
      onCancel={onClose}
      okText={summary ? 'Descargar' : 'Enviar'}
      okDisabled={!clientId || selectedFiles.length === 0}
      style={{ width: '60%', margin: '100px auto' }}
      showModal
    >
      {isFetching ? (
        <Spinner />
      ) : !!summary ? (
        <div className={styles.summary}>
          {summary.split('\n').map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </div>
      ) : (
        <>
          <AutocompleteSelect
            valueKey='clientId'
            value={clientId}
            title='Cliente'
            options={clients}
            onChange={handleClientIdChange}
            fullWidth
          />
          <div className={styles.summary}>
            <div className={styles.fileList}>
              {files
                .sort((a, b) => {
                  const dateA = parseFilenameToDate(a)
                  const dateB = parseFilenameToDate(b)
                  return dateA.getTime() - dateB.getTime()
                })
                .map((file, i) => (
                  <FormControlLabel
                    key={i}
                    name={file}
                    control={
                      <Checkbox
                        checked={selectedFiles.includes(file)}
                        onChange={e => handleFilesChange(e, file)}
                      />
                    }
                    label={file.split('-all')[0]}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </ConfirmModal>
  )
}

export default LogTimeAnalyzer
