export const TicketStatus = {
  OPEN: 'open',
  CLOSED: 'closed'
}

export const TicketStatusLabels = {
  open: 'Abierto',
  closed: 'Cerrado'
}

export const AccessTypes = {
  STUDENT: 'student',
  TRIAL: 'trial',
  TRAINEE: 'trainee'
}
export const AccessTypeLabels = {
  student: 'Alumno',
  trial: 'Prueba',
  trainee: 'Formación'
}
export const RelativePathByAccessType = {
  [AccessTypes.STUDENT]: 'alumnos',
  [AccessTypes.TRIAL]: 'leads',
  [AccessTypes.TRAINEE]: 'formaciones'
}

export const TicketTypes = {
  REGISTER: 'register',
  APP_HELP: 'appHelp',
  BUDGET: 'budget',
  QUESTION: 'question'
}

export const TicketTypeLabels = {
  register: 'Registro',
  appHelp: 'Ayuda',
  budget: 'Propuesta',
  question: 'Duda general'
}
