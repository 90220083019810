import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getClientClasses } from 'api/clients'
import { getCurrentMonth, dateFromString, dateTimeToString } from 'utils/date'
import InformationTable from 'components/InformationTable/InformationTable'
import MonthSelector from 'elements/MonthSelector/MonthSelector'
import YearSelector from 'elements/YearSelector/YearSelector'
import TeacherCell from 'components/InformationTable/cells/TeacherCell/TeacherCell'
import styles from './ClasesCliente.module.css'

const getDuration = ({ start, end }) => {
  const minutes = Math.ceil((end - start) / 60000)
  const hours = Math.floor(minutes / 60)
  if (hours > 0) {
    if (minutes % 60 > 0) return `${hours}h ${minutes % 60}m`
    return `${hours}h`
  }
  return `${minutes}m`
}

const ClasesCliente = () => {
  const { id } = useParams()
  const [date, setDate] = useState(getCurrentMonth())
  const [classes, setClasses] = useState([])

  useEffect(() => {
    setClasses([])
    getClientClasses({ clientId: id, ...date })
      .then(data =>
        setClasses(
          data.map(studentClass => ({
            ...studentClass,
            classStart: new Date(studentClass.classStart),
            classEnd: new Date(studentClass.classEnd)
          }))
        )
      )
      .catch(console.error)
  }, [id, date])

  const handleDateChange = key => e => {
    const value = e.target.value
    setDate(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  return (
    <div>
      <div className={styles.filter}>
        <MonthSelector
          value={date.month}
          onChange={handleDateChange('month')}
        />
        <YearSelector value={date.year} onChange={handleDateChange('year')} />
      </div>
      <InformationTable
        details={[
          {
            title: 'Inicio clase',
            key: 'start',
            align: 'left',
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          {
            title: 'Fin clase',
            key: 'end',
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          { title: 'Profesor', key: 'teacher', sortDisabled: true },
          {
            title: 'Duracion',
            key: 'length',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={classes.map(studentClass => ({
          _id: studentClass._id,
          start: dateTimeToString(studentClass.classStart),
          end: dateTimeToString(studentClass.classEnd),
          teacher: <TeacherCell teacher={studentClass.teacher} />,
          length: getDuration({
            start: studentClass.classStart,
            end: studentClass.classEnd
          })
        }))}
      />
    </div>
  )
}

export default ClasesCliente
