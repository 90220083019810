import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getClientDetails } from 'api/clients'
import { Typography } from '@material-ui/core'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import ClientForm from 'sections/FormularioCliente/ClientForm'
import Spinner from 'elements/Spinner/Spinner'

const DatosCliente = () => {
  const { id } = useParams()

  const { setErrorMessage } = useMessageSnackbarActions()

  const [client, setClient] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const fetchData = useCallback(() => {
    setLoading(true)
    getClientDetails(id)
      .then(setClient)
      .catch(() => setErrorMessage('No se puede mostrar la ficha'))
      .finally(() => setLoading(false))
  }, [id, setErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (isLoading) return <Spinner />
  if (!client)
    return <Typography variant='h4'>No se encontró ningún cliente</Typography>
  return <ClientForm client={client} isLoading={isLoading} />
}

export default DatosCliente
