import { GET } from 'api'

export const findClients = () =>
  GET({
    url: 'admin/clients'
  })

export const getClientDetails = clientId =>
  GET({
    url: `admin/clients/${clientId}/profile`
  })

export const getClientClasses = ({ clientId, month, year }) =>
  GET({
    url: `admin/clients/${clientId}/classes?month=${month}&year=${year}`
  })

export const getClientNotebooks = ({ clientId, month, year }) =>
  GET({
    url: `admin/clients/${clientId}/notebooks?month=${month}&year=${year}`
  })

export const getClientPhotos = ({ clientId, month, year }) =>
  GET({
    url: `admin/clients/${clientId}/photos?month=${month}&year=${year}`
  })
