import React from 'react'
import styles from './TeacherCell.module.css'

const TeacherCell = ({ teacher }) => {
  if (!teacher) return ''

  return (
    <a
      className={styles.teacher}
      href='https://core.mundoestudiante.cloud/personal/profesorado'
      target='_blank'
    >
      {teacher.name}
    </a>
  )
}

export default TeacherCell
