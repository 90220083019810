import React from 'react'
import { Link } from 'react-router-dom'
import styles from './WrapperLink.module.css'
function WrapperLink({ to, children }) {
  return (
    <Link className={styles.link} to={to}>
      {children}
    </Link>
  )
}

export default WrapperLink
