import React from 'react'
import styles from './Enlace.module.css'
function Enlace({ href, target = '_blank', children }) {
  return (
    <a className={styles.link} href={href} target={target}>
      {children}
    </a>
  )
}

export default Enlace
