import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { getClientPhotos } from 'api/clients'
import { dateToString, getCurrentMonth } from 'utils/date'
import MonthSelector from 'elements/MonthSelector/MonthSelector'
import YearSelector from 'elements/YearSelector/YearSelector'
import styles from './FotosCliente.module.css'

const LargePhotoModal = ({ open, onClose, url }) => (
  <Dialog open={open} onClose={onClose} aria-labelledby='foto'>
    <DialogContent>
      <img className={styles.largePhotoModal} src={url} alt='Foto grande' />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color='secondary'>
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
)

const FotosCliente = () => {
  const { id } = useParams()
  const [date, setDate] = useState(getCurrentMonth())
  const [photos, setPhotos] = useState([])
  const [selectedImage, setSelectedImage] = useState('')

  useEffect(() => {
    setPhotos([])
    getClientPhotos({ clientId: id, ...date })
      .then(data => {
        setPhotos(
          data
            .map(photo => ({ ...photo, date: new Date(photo.date) }))
            .sort((photo1, photo2) => photo1.date - photo2.date)
            .map(photo => ({ ...photo, date: dateToString(photo.date) }))
            .reduce((acc, curr) => {
              const day = acc.find(e => e.date === curr.date) || {
                date: curr.date,
                images: []
              }
              return [
                ...acc.filter(e => e.date !== curr.date),
                {
                  ...day,
                  images: [
                    ...day.images,
                    { location: curr.location, thumbnail: curr.thumbnail }
                  ]
                }
              ]
            }, [])
        )
      })
      .catch(console.error)
  }, [id, date])

  const handleDateChange = key => e => {
    const value = e.target.value
    setDate(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  return (
    <>
      <div>
        <div className={styles.filter}>
          <MonthSelector
            value={date.month}
            onChange={handleDateChange('month')}
          />
          <YearSelector value={date.year} onChange={handleDateChange('year')} />
        </div>
        <div>
          {photos.map(photo => (
            <div key={photo.date}>
              <p className={styles.gridDayInfo}>
                Día {photo.date} - {photo.images.length} fotos subidas
              </p>
              <div>
                {photo.images.map(image => (
                  <img
                    key={image.thumbnail}
                    className={styles.gridPhoto}
                    src={image.thumbnail}
                    alt='Foto Cliente'
                    onClick={() => setSelectedImage(image.location)}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <LargePhotoModal
        open={!!selectedImage}
        url={selectedImage}
        onClose={() => setSelectedImage('')}
      />
    </>
  )
}

export default FotosCliente
