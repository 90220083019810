import React from 'react'
import { AppBar, Toolbar, Tooltip, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getAuthenticationDetails } from '../../utils'
import config from 'config'
import logo from './resources/logo.png'
import styles from './Header.module.css'

const noop = () => {}

const Header = ({ onLogout = noop }) => {
  return (
    <AppBar className={styles.header}>
      <Toolbar className={styles.toolbar}>
        <Link to='/'>
          <img className={styles.logo} src={logo} alt='mundoestudiante' />
        </Link>
        {config.environmentTag && (
          <Typography variant='h6' className={styles.highlight}>
            {config.environmentTag}
          </Typography>
        )}
        <div className={styles.actions}>
          <Link
            className={styles.link}
            to='#'
            onClick={() => window.location.reload(true)}
          >
            <i className='material-icons'>cached</i>
          </Link>
          <Tooltip
            title={getAuthenticationDetails()?.username || 'Sin identificar'}
          >
            <i className='material-icons'>account_circle</i>
          </Tooltip>
          <Link className={styles.link} to='#' onClick={onLogout}>
            <i className='material-icons'>power_settings_new</i>
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
